<template>
    <div class="layout-header">
        <div class="header-logo">
            <img class="a-bounceinT" src="../../assets/Header/logo.png"/>
        </div>
        <div class="header-links">
            <a class="a-fadeinR" :class="point === header.url?'active link1_'+(key+1):'link1_'+(key+1)" href="javascript:" @click="onLinks(header.url)" :key="key" v-for="(header,key) in headerObj">{{ header.name }}</a>
        </div>
    </div>
</template>

<script>
import Header from './header'
export default Header
</script>
<style lang="less">
@import "header";
</style>
