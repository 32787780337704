<template>
    <div class="layout-footer">
        <div class="footer-links">
            <a href="javascript:" @click="onLinks"><img src="../../assets/Footer/location-icon.png"></a>
            <a href="javascript:" @click="onLinks"><img src="../../assets/Footer/mailbox-icon.png"></a>
            <a href="javascript:" class="code">
                <img src="../../assets/Footer/wx-icon.png">
                <div class="code-box">
                    <img src="../../assets/code.png">
                </div>
            </a>
        </div>
        <div class="footer-record">
            <p>京公安备11010502041722</p>
            <p><a href="https://beian.miit.gov.cn/"  target="_blank">京ICP备20022529号</a></p>
        </div>
    </div>
</template>

<script>
import Footer from './footer'
export default Footer
</script>
<style lang="less">
@import "footer";
</style>
