/**
 * index
 */

import Header from '@/components/Header/header.vue'
import Footer from '@/components/Footer/footer.vue'
const Index = {
    name:'Index',
    components:{ Header,Footer },
    data() {
        return {

        }
    },
    mounted() {
        // let home = document.getElementById("home");
        // let about = document.getElementById("about");
        // let service = document.getElementById("service");
        // let core = document.getElementById("core");
        // let caseClass = document.getElementById("case");
        // let contact = document.getElementById("contact");
        // //3.整个文档绑定鼠标移动事件
        // var delX = 0;
        // var delY = 0;
        // document.onmousemove = function () {
        //     var box = document.getElementById('pointer')
        //
        //     var x = event.clientX - delX;
        //     var y = event.clientY - delY;
        //     box.style.transfrom = 'translateX(' + x + 'px)'
        //     box.style.transfrom = 'translateY(' + y + 'px)'
        // };
        // document.onmousemove = function () {
        //     var odiv= document.getElementById("pointer");
        //     if(window.event.x>=home.offsetLeft && window.event.x<= home.offsetLeft+home.offsetWidth && window.event.y>= 33 && window.event.y <= 33+home.offsetHeight){
        //         odiv.className = 'pointer withText'
        //     }else if(window.event.x>=about.offsetLeft && window.event.x<= about.offsetLeft+about.offsetWidth && window.event.y>= 33 && window.event.y <= 33+about.offsetHeight){
        //         odiv.className = 'pointer withText'
        //     }else if(window.event.x>=service.offsetLeft && window.event.x<= service.offsetLeft+service.offsetWidth && window.event.y>= 33 && window.event.y <= 33+service.offsetHeight){
        //         odiv.className = 'pointer withText'
        //     }else if(window.event.x>=core.offsetLeft && window.event.x<= core.offsetLeft+core.offsetWidth && window.event.y>= 33 && window.event.y <= 33+core.offsetHeight){
        //         odiv.className = 'pointer withText'
        //     }else if(window.event.x>=caseClass.offsetLeft && window.event.x<= caseClass.offsetLeft+caseClass.offsetWidth && window.event.y>= 33 && window.event.y <= 33+caseClass.offsetHeight){
        //         odiv.className = 'pointer withText'
        //     }else if(window.event.x>=contact.offsetLeft && window.event.x<= contact.offsetLeft+contact.offsetWidth && window.event.y>= 33 && window.event.y <= 33+contact.offsetHeight){
        //         odiv.className = 'pointer withText'
        //     }else{
        //         odiv.className = 'pointer'
        //         this.visible = false
        //     }
        //     odiv.style.transform = 'translateY(' + parseInt(window.event.y-40) + 'px) translateX(' + parseInt(window.event.x-40) + 'px)'
        // }
    },
    methods:{

    },
}
export default Index
