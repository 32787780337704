import {mapMutations, mapState} from "vuex";

/**
 * header
 * @type {{data(): {}}}
 */
const Header = {
    name:'Header',
    computed: {
        ...mapState({
            point: state => state.header.point,  //获取状态header锚点
        }),
    },
    data(){
        return {
            active: false,
            headerObj: [
                {
                    url:'home',
                    name:'首页',
                },
                {
                    url:'about',
                    name:'关于昊明数科',
                },
                {
                    url:'service',
                    name:'营销服务',
                },
                {
                    url:'core',
                    name:'核心产品',
                },
               /* {
                    url:'case',
                    name:'服务案例',
                },*/
                {
                    url:'joinUs',
                    name:'昊明直通车',
                },
                {
                    url:'contact',
                    name:'联系我们',
                }
            ]
        }
    },
    methods:{
        ...mapMutations('header',['SET_POINT']),
        onLinks(url){
            if(this.$route.path === '/'){
                switch (url){
                    case 'home':
                        this.SET_POINT('home')
                        window.location.href = '#home'
                        break;
                    case 'about':
                        this.SET_POINT('about')
                        window.location.href = '#about'
                        break;
                    case 'service':
                        this.SET_POINT('service')
                        window.location.href = '#service'
                        break;
                    case 'core':
                        this.SET_POINT('core')
                        window.location.href = '#core'
                        break;
                    case 'case':
                        this.SET_POINT('case')
                        window.location.href = '#case'
                        break;
                    case 'joinUs':
                        this.SET_POINT('joinUs')
                        window.location.href = '#joinUs'
                        break;
                    case 'contact':
                        this.SET_POINT('contact')
                        window.location.href = '#contact'
                        break;
                }
            }else{
                this.$router.push('/')
                window.location.href = '#'+url
            }
        }
    }
}
export default Header
