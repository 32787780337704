<template>
    <div>

        <!--s: header-->
        <Header />
        <!--e: header-->

        <!--s: footer-->
        <Footer/>
        <!--e: footer-->

        <router-view/>


<!--        <div class="pointer" id="pointer">-->
<!--            <div class="circle"></div>-->
<!--        </div>-->
    </div>
</template>

<script>
import Index from './index'
export default Index
</script>
<style lang="less">
@import "index";
</style>
