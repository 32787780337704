/**
 * Footer
 * @type {{data(): {}}}
 */
const Footer = {
    name:'Footer',
    data(){
        return{

        }
    },
    methods:{
        onLinks(){
            this.$router.push('/')
            window.location.href = '#contact'
        }
    }
}
export default Footer
